.home-section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-section1-gif {
  margin: 2% 0 0 2%;
  border-radius: 50%;
  box-shadow: 5px 5px 50px white;
  max-width: 80vw;
}

.home-section1-info {}

.home-title {
  font-family: 'Faster One';
  font-size: 2.5rem;
  color: #18B9C3
}

.home-section1-info-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



@media screen and (width >=800px) {

  .home-section1 {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .home-section1-info-btn {
    flex-direction: row;
    justify-content: space-around;
  }

  .home-title {
    font-size: 3rem;
  }

  .home-section1-gif {
    width: 30%;
  }
}

@media screen and (width >=1024px) {

  .home-title {
    font-size: 6rem;
  }


  .home-section1-gif {
    margin: 2% 0 0 2%;
  }

  .swiper,
  .mySwiper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90vh;
    max-width: 100vw;
    position: relative;

  }

}

.swiper,
.mySwiper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100vw;
  position: relative;

}

.swiper-slide:hover {
  position: relative;
  transform: scaleX(1.2);
  z-index: 1;
}

.swiper-slide {
  display: flex;
  transition: transform 0.3s ease, z-index 0s linear 0.3s;
  transform-origin: center;
}

.swiper-slide2 {
  background-image: url('../../assets/mujer.jpg');
}

.swiper-slide1 {
  background-image: url('../../assets/menPhoto.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.swiper-slide1,
.swiper-slide3 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 255, 255, 0.322);
}

.swiper-slide img {
  width: 100%;
  align-self: center;
}





.container-home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 40vh;
  text-align: center;
  background-color: white;
}

.container-home h1 {

  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
  background: url('../../assets/sports.jpeg');
  -webkit-background-clip: text;
  background-attachment: fixed;
  font-size: 4rem;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
}

@media screen and (min-width: 700px) {
  .container-home {
    height: 70vh;
  }
  .container-home h1 {
    font-size: 10rem;
  }
}

@media screen and (min-width: 1024px) {
  .container-home h1 {
    font-size: 13rem;
  }
}