

form {
  background-color: transparent;
  border: none;
}

label {
  color: black;
  font-weight: 500;
}

fieldset{
  padding-top: 5%;
  background-color: #ffffff77;
}

.form-input {
  padding: 1% 0 1% 0;
  min-height: 25px;
  border: rgb(167, 167, 167);
  background-color: rgba(220, 220, 220, 0.507);
}

legend {
  color: black;
}

.form-uniform{
  display: flex;
  width: 100%;
  padding: 0;
  margin-bottom: 2%;
  justify-content: center;
  flex-direction: column;
}




h3 {
  background: blue;
  color: #fff;
  font-size: 36px;
  line-height: 100px;
  margin: 10px;
  padding: 2%;
  position: relative;
  text-align: center;
}

.variable-width .slick-slide p {
  background: blue;
  height: 100px;
  color: #fff;
  margin: 5px;
  line-height: 100px;
  text-align: center;
}

.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}

.center h3 {
  opacity: 0.8;
  transition: all 300ms ease;
}

.content {
  padding: 20px;
  margin: auto;
  width: 90%;
}

.slick-slide .image {
  padding: 10px;
}

.slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
}

.slick-slide img.slick-loading {
  border: 0;
}

.slick-slider {
  margin: 30px auto 50px;
}

.slick-dots {
  margin-left: 0;
}

.slick-thumb {
  bottom: -45px;
}

.slick-thumb li {
  width: 60px;
  height: 45px;
}

.slick-thumb li img {
  filter: grayscale(100%);
}

.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}

@media screen and (width >=768px) {
  h3 {
    font-size: 24px;
  }

  .center {
    margin-left: -40px;
    margin-right: -40px;
  }

  .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1);
  }

  .center h3 {
    opacity: 0.8;
    transform: scale(0.95);
    transition: all 300ms ease;
  }
  .form-uniform{
    flex-direction: row;
  }
  
}

.slick-vertical .slick-slide {
  height: 180px;
}

.slick-arrow {
  background-color: grey;
}

.slick-arrow:hover {
  background-color: grey;
}


.banner-uniform img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .uniform-user-box{
    width: 30%;
  }
  
  .uniform-design-box{
    width: 60%;
  }
}

@media screen and (min-width: 768px) {
  
}