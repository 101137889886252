.messenger-icon-link {
    position: fixed;
    bottom: 0;
    background-color: aliceblue;
    right: 0;
    padding: 0.5%;
    margin:2%;
    border-radius: 100%;
    z-index: 1;
}

.messenger-icon{
    width: 100%;
    color: aliceblue;
}

@media screen and (width >=800px) {
    .messenger-icon-link{
    }
}