.title{
    color: #032D57;
    font-family: Squada One; 
    font-size: 1.5rem;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .title{
        font-size: 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .title{
        font-size: 3rem;
    }
}